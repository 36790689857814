/* Slider CSS */
.containerFrame .imageCaption{
    position: absolute;
    bottom: 5%;
    left: 20%;
    width: 60%;
}

.divControles{
    display: flex;
    font-weight: bold;
    color: black;
    justify-content: center;
}

.numberSlide{
    background-color: #e2e6ea;
    text-align: center;
    border-radius: 3px;
    padding: 4px 8px;
    margin: 5px 10px;
}

.slideFrame{
    border-radius: 5px;
    height: 325px;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .slideFrame{
        height: 275px;
    }
}

@media screen and (min-width: 900px) {
    .slideFrame{
        height: 325px;
    }
}

@media screen and (min-width: 1020px) {
    .slideFrame{
        height: 350px;
    }
}

@media screen and (min-width: 1100px) {
    .slideFrame{
        height: 375px;
    }
}

/* LightBox CSS */

.zoom .containerFrame .imageCaption{
    position: absolute;
    bottom: 5%;
    left: 30%;
    width: 40%;
}

.zoom .numberSlide{
    background-color: #e2e6ea;
    text-align: center;
    border-radius: 3px;
    padding: 4px 8px;
    margin: 5px 10px;
}

.zoom .slideFrame{
    border-radius: 5px;
    height: 70vh;
    width: 100%;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .zoom .slideFrame{
        height: 40vh;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .zoom .slideFrame{
        height: 50vh;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .zoom .slideFrame{
        height: 60vh;
    }
}

/* @media screen and (max-width: 450px) {
    .zoom .slideFrame{
        height: 15vh;
    }
}

@media screen and (max-width: 600px) {
    .zoom .slideFrame{
        height: 35vh;
    }
}

@media screen and (max-width: 700px) {
    .zoom .slideFrame{
        height: 50vh;
    }
} */

/* @media screen and (max-width: 750px) {
    .zoom .slideFrame{
        height: 60vh;
    }
} */

@media screen and (min-width: 900px) {
    .zoom .slideFrame{
        height: 80vh;
    }
}

@media screen and (min-width: 1020px) {
    .zoom .slideFrame{
        height: 80vh;
    }
}

@media screen and (min-width: 1100px) {
    .zoom .slideFrame{
        box-sizing: border-box;
        height: 80vh;
    }
}