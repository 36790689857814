// 
// _horizontal.scss
// 

.topnav {
    background: $topnav-bg;
    padding: 0 calc(#{$grid-gutter-width} / 2);
    box-shadow: $box-shadow-lg;
    margin-top: $header-height;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;

    @media (max-width: 991.98px){
        background: #012F20;
    }
    
    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        
        .nav-link {
            font-size: 14.5px;
            position: relative;
            padding: 1rem 1.3rem;
            color: rgba($white, 0.5);
            i{
                font-size: 15px;
                display: inline-block;
            }
            &:focus, &:hover{
                color: $white;
                background-color: transparent;
                @media (max-width: 991.98px){
                    color: $menu-item-active-color;
                }
            }

            @media (max-width: 991.98px){
                color: $header-item-color;
            }
        }

        > .dropdown{
            &.active{
              >a {
                    color: rgba($white, 0.9) !important;

                    @media (max-width: 991.98px){
                        color: $menu-item-active-color !important;
                    }
                }
            }
        }
        
        
        .dropdown-item{
            color: $sidebar-menu-item-color;
            &.active, &:hover{
                color: $sidebar-menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .nav-item{
            .nav-link.active{
                color: rgba($white, 0.9);

                @media (max-width: 991.98px){
                    color: $menu-item-active-color !important;
                }
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $sidebar-menu-item-active-color;
                    background-color: transparent;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 85%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
        }

        .dropdown {
            &.mega-dropdown{
                // position: static;
                .mega-dropdown-menu{
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        display: none
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}

body[data-layout="horizontal"]  #page-topbar{
    @media (min-width: 992px) {
        padding: 0 calc(#{$grid-gutter-width} / 2);
    }
}


@include media-breakpoint-down(lg) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    
    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;

                &.mega-dropdown-menu{
                    width: auto;
    
                    .row{
                        margin: 0px;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}