/* .containerLB{
    position: relative;
    height: 100%;
    justify-content: space-around;
    
}

.containerLB .lbCaption{
    position: absolute;
    bottom: 0%;
    width: 45%;
} */


/* ======================================================================== */
.lbModal{
    height: 100%;
}

.containerLB{
    height: 100%;
}

.containerLB .lbCaption{
    position: fixed;
    bottom: 5%;
    left: 30%;
    width: 40%;
}

.lbControles{
    display: flex;
    font-weight: bold;
    color: black;
    justify-content: center;
}

.lmNumberSlide{
    background-color: #e2e6ea;
    text-align: center;
    border-radius: 3px;
    padding: 4px 8px;
    margin: 5px 10px;
}

.lbSlideFrame{
    border-radius: 5px;
    height: 325px;
    width: 100%;
}


/* 
@media screen and (max-width: 500px) {
    .slideFrame{
        height: 275px;
    }
}

@media screen and (min-width: 900px) {
    .slideFrame{
        height: 325px;
    }
}

@media screen and (min-width: 1020px) {
    .slideFrame{
        height: 350px;
    }
}

@media screen and (min-width: 1100px) {
    .slideFrame{
        height: 375px;
    }
} */