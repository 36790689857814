

.item-phone{
    margin: 0;
    padding: 0;
    color: white;
}

.text-justify{
    text-align: justify;
    text-justify: inter-word;
}


@media screen and (max-width: 700px){
    .item-phone{
        display: none;
    }
}


.anuncioContent{
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
}

.spaceBetween{
    justify-content: space-between;
}

.anuncioInfo{
    margin-top: 20px;
    margin-bottom: 20px;
}

.anuncioValor{
    color: #175B28;
    font-weight: bold;
}

.cardText{
    color: gray;
    font-size: 12px;
}

.cardContent{
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.containerImage{
    height:240px;
}

.cardImage{
    position: relative;
    height: 100%;
    width: 100%;
    border-radius:5px;
}

.containerImage .cardImageCaption{
    position: relative;
    bottom: 32%;
    left: 20%;
    width: 60%;
}

@media screen and (min-width: 1080px){
    .containerImage{
        height:240px;
    }
}

@media screen and (max-width: 1020px){
    .containerImage{
        height:200px;
    }
}

@media screen and (max-width: 950px){
    .containerImage{
        height:170px;
    }
}

@media screen and (max-width: 900px){
    .containerImage{
        height:150px;
    }
}

@media screen and (max-width: 767px){
    .containerImage{
        height:350px;
    }

    .containerImage .cardImageCaption{
        bottom: 35%;
    }
}

@media screen and (max-width: 575px){
    .containerImage{
        height:250px;
    }

    .containerImage .cardImageCaption{
        bottom: 35%;
    }
}

@media screen and (max-width: 390px){
    .containerImage{
        height:170px;
    }
}

.dnldBadges{
    justify-content: center;
}

.dnldBadges .badge-item{
    margin: 5px;
}

@media screen and (max-width: 463px){
    .dnldBadges .badge-item{
        margin: 2;
    }
}